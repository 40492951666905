<template>
  <v-container fluid ma-0 pa-0>
    <div class="purple-gradient fill-height">
      <Navbar page="provider" />
    </div>
    <div
      v-if="reservation && !loading"
      :style="[
        $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
          ? {
              'padding-top': '30px',
            }
          : {
              'padding-top': '60px',
            },
      ]"
    >
      <v-container class="px-10 px-md-auto">
        <div class="text-h1 font-weight-black mb-2">Recibo</div>
        <div class="d-flex flex-column flex-md-row mt-2 ml-1">
          <div class="text-h4 mr-sm-10">
            No. de confirmación: {{ reservation.id }}
          </div>
        </div>
        <Button
          text="Volver"
          pill
          outlined
          :height="43"
          prepend-icon="fa-caret-left"
          class="mt-9 mb-8"
          aria-label="Volver a lista de reservaciones"
          url="ProviderReservations"
        />
        <v-row>
          <v-col cols="12" md="6">
            <v-card
              elevation="0"
              class="mx-1 pa-7 text-h4 rounded-lg blue-border"
            >
              <div class="pb-1 text-h3 font-weight-black">
                Información del cliente
              </div>
              <div class="mt-4">
                {{ reservation.quotationRequest.requesterName }}
              </div>
              <div class="mt-4">
                {{
                  reservation.quotationRequest.eventService.event.user
                    .preferedName
                }}
              </div>
              <div class="mt-4">
                {{ reservation.quotationRequest.requesterEmail }}
              </div>
              <div class="mt-4">
                {{ reservation.quotationRequest.requesterPhone }}
              </div>
              <div class="mt-4">
                {{ reservation.quotationRequest.requesterCity }},
                {{ reservation.quotationRequest.requesterState }}
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card
              elevation="0"
              class="mx-1 pa-7 text-h4 rounded-lg blue-border"
            >
              <div class="pb-1 text-h3 font-weight-black">
                Información del proveedor
              </div>
              <div class="mt-4">
                <router-link
                  class="black--text text-decoration-underline"
                  :to="getProviderRoute"
                >
                  {{
                    reservation.quotationRequest.eventService.service.provider
                      .name
                  }}
                </router-link>
              </div>
              <div class="mt-4">
                <router-link
                  class="black--text text-decoration-underline"
                  :to="getServiceRoute"
                >
                  {{ reservation.quotationRequest.eventService.service.name }}
                </router-link>
              </div>
              <div class="mt-4">
                {{ providerAddress }}
              </div>

              <div class="mt-4">
                {{
                  reservation.quotationRequest.eventService.service.provider
                    .phone
                }}
              </div>
              <div class="mt-4">
                {{
                  reservation.quotationRequest.eventService.service.provider
                    .email
                }}
              </div>
            </v-card>
          </v-col>
        </v-row>
        <div class="py-16">
          <div class="mx-7 text-h4">
            <div class="mb-5 mb-md-0">
              <div class="text-h3 font-weight-black">
                Información de reservación
              </div>
            </div>
            <v-row class="align-center mt-4" no-gutters>
              <v-col cols="12" sm="5" md="4">
                <span>Ciudad del evento</span>
              </v-col>
              <v-col cols="12" sm="7" md="8">
                <span>
                  {{ reservation.quotationRequest.eventCity }}
                </span>
              </v-col>
            </v-row>
            <v-row class="align-center mt-4" no-gutters>
              <v-col cols="12" sm="5" md="4">
                <span>Dirección</span>
              </v-col>
              <v-col cols="12" sm="7" md="8">
                <span>
                  {{ reservation.quotationRequest.eventAddress }}
                </span>
              </v-col>
            </v-row>
            <v-row class="align-center mt-4" no-gutters>
              <v-col cols="12" sm="5" md="4">
                <span>Fecha del evento</span>
              </v-col>
              <v-col cols="12" sm="7" md="8">
                <span>
                  {{
                    dateFormatterWithTime(
                      reservation.quotationRequest.eventDate
                    )
                  }}
                </span>
              </v-col>
            </v-row>
            <v-row class="align-center mt-4" no-gutters>
              <v-col cols="12" sm="5" md="4">
                <span>Tipo de evento</span>
              </v-col>
              <v-col cols="12" sm="7" md="8">
                <span>
                  {{ reservation.quotationRequest.eventType }}
                </span>
              </v-col>
            </v-row>
            <v-row class="align-center mt-4" no-gutters>
              <v-col cols="12" sm="5" md="4">
                <span> Número de invitados</span>
              </v-col>
              <v-col cols="12" sm="7" md="8">
                <span>
                  {{ reservation.quotationRequest.peopleQuantity }}
                </span>
              </v-col>
            </v-row>
            <v-row class="align-center mt-4" no-gutters>
              <v-col cols="12" sm="5" md="4">
                <span>Comentarios</span>
              </v-col>
              <v-col cols="12" sm="7" md="8">
                <span class="text-pre-line">
                  {{
                    reservation.quotationRequest.clientComment
                      ? reservation.quotationRequest.clientComment
                      : "No se han proporcionado comentarios."
                  }}
                </span>
              </v-col>
            </v-row>
            <div class="mt-10 mb-5 mb-md-0">
              <div class="text-h3 font-weight-black">
                Desglose de servicio solicitado
              </div>
            </div>
            <v-row class="align-center mt-4" no-gutters>
              <v-col cols="12" sm="5" md="4">
                <span>Tipo de servicio</span>
              </v-col>
              <v-col cols="12" sm="7" md="8">
                <span>
                  {{
                    reservation.quotationRequest.eventService.service.category
                  }}
                </span>
              </v-col>
            </v-row>
            <v-row class="align-center mt-4" no-gutters>
              <v-col cols="12" sm="5" md="4">
                <span>Costo de servicio (IVA incluido)</span>
              </v-col>
              <v-col cols="12" sm="7" md="8">
                <span>
                  {{
                    numberToCurrency(
                      reservation.quotationRequest.paymentProvider
                    )
                  }}
                  MXN
                </span>
              </v-col>
            </v-row>
            <v-row class="align-center mt-4" no-gutters>
              <v-col cols="12" sm="5" md="4">
                <span>Comisión Stripe (4.2%)</span>
              </v-col>
              <v-col cols="12" sm="7" md="8">
                <span>
                  {{
                    numberToCurrency(
                      reservation.quotationRequest.paymentProvider *
                        stripeCommission
                    )
                  }}
                  MXN
                </span>
              </v-col>
            </v-row>
            <v-row class="align-center mt-4" no-gutters>
              <v-col cols="12" sm="5" md="4">
                <span>Comisión Zaturna 0%</span>
              </v-col>
              <v-col cols="12" sm="7" md="8">
                <span>
                  {{
                    numberToCurrency(
                      reservation.quotationRequest.totalPayment *
                        providerCommission
                    )
                  }}
                  MXN
                </span>
              </v-col>
            </v-row>
            <v-row class="align-center mt-4" no-gutters>
              <v-col cols="12" sm="5" md="4">
                <span class="font-weight-bold">Cantidad a recibir</span>
              </v-col>
              <v-col cols="12" sm="7" md="8">
                <span class="font-weight-bold">
                  {{ numberToCurrency(totalProvider) }}
                  MXN
                </span>
              </v-col>
            </v-row>
            <v-row class="align-center mt-4" no-gutters>
              <v-col cols="12" sm="5" md="4">
                <span> Cotización de servicio</span>
              </v-col>
              <v-col cols="12" sm="7" md="8">
                <Button
                  text="Ver"
                  class="my-2 my-md-4"
                  aria-label="Descargar cotización de servicio"
                  download
                  is-external
                  :url="reservation.quotationRequest.quotationUrl"
                />
              </v-col>
            </v-row>
            <div class="mt-10 mb-5 mb-md-0">
              <div class="text-h3 font-weight-black">Plan de pagos</div>
            </div>
            <div class="mt-10 mb-5 mb-md-0">
              <v-row
                v-for="(pays, index) in reservation.payment"
                :key="index"
                no-gutters
                class="my-4"
              >
                <v-col cols="12" md="4">
                  <div>
                    {{ getText(index) }}
                  </div>
                </v-col>
                <v-col cols="12" md="3">
                  <div>
                    {{ numberToCurrency(pays.total) }}
                  </div>
                </v-col>
                <v-col cols="12" md="5">
                  <div>{{ dateFormatter(pays.date) }}</div>
                </v-col>
              </v-row>
              <v-row
                v-if="reservation.status !== 'paid'"
                no-gutters
                class="my-2 my-sm-4"
              >
                <v-col cols="12" md="4">
                  <div>Liquidación</div>
                </v-col>
                <v-col cols="12" md="3">
                  <div>
                    {{
                      numberToCurrency(
                        reservation.quotationRequest.totalPayment -
                          reservation.totalPaid
                      )
                    }}
                  </div>
                </v-col>
                <v-col cols="12" md="5">
                  <div
                    v-if="reservation.quotationRequest.paymentAdvance !== 100"
                  >
                    <span class="font-italic"> Fecha límite </span>
                    {{
                      dateFormatter(
                        reservation.quotationRequest.paymentDeadline
                      )
                    }}
                  </div>
                </v-col>
              </v-row>
              <v-row no-gutters class="my-2">
                <v-col cols="12" md="4">
                  <div>Importe pagado</div>
                </v-col>
                <v-col cols="12" md="3">
                  <div>
                    {{ numberToCurrency(reservation.totalPaid) }}
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </v-container>

      <v-row>
        <!-- 
      <v-col sm="12">
        <v-row>
          <v-col cols="1"></v-col>
          <v-col cols="10" sm="10" md="8">
            <template>
              <h2>Contrato</h2>
              <br />
              <v-simple-table dense>
                <template>
                  <tbody>
                    <tr>
                      <td class="py-2 px-0" width="200px">
                        Contrato proveedor
                      </td>
                      <td class="pl-sm-16">
                        <Button
                          text="Ver"
                          class="my-3"
                          aria-label="Descargar contrato del proveedor"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="pt-0 px-0" width="100px" color="descent">
                        <i
                          >Firmado {{ dateFormater(paymentDetails[0].data) }}</i
                        >
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
          </v-col>
          <v-col cols="0" sm="4"> </v-col>
        </v-row>
      </v-col> -->
      </v-row>
      <Footer class="mt-15" />
    </div>
    <v-container v-else>
      <InlineLoading />
    </v-container>
  </v-container>
</template>

<script>
import Navbar from "@/components/Provider/Shared/Navbar.vue";
import Button from "@/components/Shared/Button.vue";
import { mapState } from "vuex";
import { GET_RESERVATION_DETAILS } from "@/graphql/queries";
import { useQuery } from "@/graphql/index";
import { numberToCurrency } from "@/Utils/numberFormatter.js";
import {
  convertUnixToDDMMYYYY,
  unixToHourMinuteEdit,
  convertUnixToAmPm,
} from "@/Utils/dateConverter.js";
import Footer from "@/components/Shared/Footer.vue";
import InlineLoading from "@/components/Shared/InlineLoading.vue";
import { formatProviderAddress } from "@/Utils/Utils";

export default {
  components: { Navbar, Button, Footer, InlineLoading },
  filters: {
    percent(dec) {
      if (dec <= 1) {
        return dec * 100 + "%";
      }
    },
  },
  data: () => ({
    reservation: {
      payment: [],
      quotationRequest: {
        eventService: {
          service: {
            name: "",
          },
        },
      },
    },
    loading: true,
    quotationFetching: false,
    quotationFetched: false,
    stripeCommission: 0.042,
    providerCommission: 0.0,
  }),
  computed: {
    ...mapState({
      provider(state) {
        return state.provider;
      },
    }),
    providerAddress() {
      const provider =
        this.reservation.quotationRequest.eventService.service.provider;
      return formatProviderAddress(provider);
    },
    totalProvider() {
      const totalPayment = this.reservation.quotationRequest.paymentProvider;
      return (
        totalPayment -
        totalPayment * (this.stripeCommission + this.providerCommission)
      );
    },
    getProviderRoute() {
      return {
        name: "UserProvider",
        params: {
          id: this.reservation.quotationRequest.eventService.service.provider
            .id,
        },
      };
    },
    getServiceRoute() {
      return {
        name: "UserService",
        params: {
          id: this.reservation.quotationRequest.eventService.service.id,
        },
      };
    },
  },
  watch: {
    provider: {
      async handler(provider) {
        if (!provider.id) return;
        if (!this.quotationFetching && !this.quotationFetched) {
          this.quotationFetching = true;
          await this.fetchReservation();
          this.quotationFetched = true;
          this.quotationFetching = false;
        }
      },
      immediate: true,
    },
  },
  methods: {
    async fetchReservation() {
      const props = {
        id: parseInt(this.$route.params.id),
        clientId: parseInt(this.provider.id),
        route: "provider",
      };

      try {
        const { data, errors } = await useQuery(GET_RESERVATION_DETAILS, props);

        if (errors) throw errors;

        this.reservation = data.reservation;
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.$router.push({ name: "ProviderReservations" });
      }
    },
    getText(index) {
      return index === 0
        ? "Anticipo " + this.reservation.quotationRequest.paymentAdvance + "%"
        : index == this.reservation.payment.length - 1 &&
          this.reservation.status === "paid"
        ? "Liquidación "
        : "Pago " + index;
    },
    dateFormatter(unixTime) {
      return convertUnixToDDMMYYYY(unixTime);
    },
    dateFormatterWithTime(unixTime) {
      return (
        convertUnixToDDMMYYYY(unixTime) +
        " " +
        unixToHourMinuteEdit(unixTime) +
        " " +
        convertUnixToAmPm(unixTime)
      );
    },
    numberToCurrency(number) {
      return numberToCurrency(number);
    },
  },
};
</script>

<style scoped>
.v-data-table td {
  border-bottom: none !important;
}

tr:hover {
  background-color: transparent !important;
}

.v-data-table tr {
  vertical-align: 0;
}
.v-input--selection-controls {
  margin-top: 0;
  padding: 0;
}
.purple-gradient {
  background-color: rgba(98, 37, 130, 1);
}
.blue-border {
  border: 1px solid var(--v-secondary-base);
}
</style>
